












import Vue from 'vue'

interface Event<T = EventTarget> {
  target: T;
}

export default Vue.extend({
  props: ['file'],
  methods: {
    updateValue (e: Event<HTMLInputElement>) {
      const { files } = e.target

      if (files) {
        this.$emit('input', files[0])
      }
    }
  }
})
